import { render, staticRenderFns } from "./DelayBody.vue?vue&type=template&id=24d9324e&scoped=true"
import script from "./DelayBody.vue?vue&type=script&lang=js"
export * from "./DelayBody.vue?vue&type=script&lang=js"
import style0 from "./DelayBody.vue?vue&type=style&index=0&id=24d9324e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d9324e",
  null
  
)

export default component.exports