<template>
  <div class="drumkit-body"></div>
</template>

<script>
export default {
  name: 'DrumkitBody',
  props: ['Node'],
  methods: {},
};
</script>